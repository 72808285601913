.title {
  padding: 8px 0;
  text-align: center;
}

.logo_container {
  height: 48px;
  margin-bottom: 4px;
}

.logo {
  height: 48px;
}

@media print {
  .hideOnPrint {
    display: none;
  }
}
